import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import EvaIcon from './EvaIcon';

class ErrorModal extends Component {
    handle_close = () => {if (this.props.onClose) this.props.onClose();};

    render() {
        const { t } = this.props;
        const { open, descriptionKey } = this.props;

        return (
            <Modal isOpen={open} toggle={this.handle_close}>
                <ModalHeader toggle={this.handle_close}>{t('ui.errorModal.headerLabel')}</ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center">
                    <EvaIcon name="close" style={styles.icon}/>
                    <span className="flex-fill mx-4">{t(descriptionKey)}</span>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.handle_close}>{t('ui.errorModal.closeButtonLabel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

ErrorModal.propTypes = {
    open: PropTypes.bool.isRequired,
    descriptionKey: PropTypes.string.isRequired,
    onClose: PropTypes.func
};

const styles = {
    icon: {
        color: '#e74c3c',
        fontSize: 70
    }
};

export default withTranslation()(ErrorModal);