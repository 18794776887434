import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Voting from '../pages/Voting/Voting';
import Panel from '../pages/Panel/Panel/Panel';
import Confirm from '../pages/Confirm/Confirm';
import Impressum from '../pages/Impressum/Impressum';
import AOA from '../pages/404/404';

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<Voting/>}/>
            <Route path='/admin' element={<Panel/>}/>
            <Route path='/confirm/:token' element={<Confirm/>}/>
            <Route path='/impressum' element={<Impressum/>}/>
            <Route path='*' element={<AOA/>}/>
        </Routes>
    </BrowserRouter>
);

export default Router;
