import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Input, Button } from 'reactstrap';

import './LoginScreen.scss';

import Api from '../../../api/Api';
import EvaIcon from '../../../components/EvaIcon';

class LoginScreen extends Component {
    state = {
        username: '',
        password: '',
        loading: false,
        error: false
    };

    handle_usernameChange = (e) => this.setState({ username: e.target.value });

    handle_passwordChange = (e) => this.setState({ password: e.target.value });

    handle_keyPress = (e) => {if (e.key === 'Enter') this.handle_submit();};

    handle_submit = async () => {
        const { username, password } = this.state;

        this.setState({ loading: true });

        try {
            const user = await Api.login(username, password);
            if (this.props.onLogin) this.props.onLogin(user.user);
        } catch (error) {
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { t } = this.props;
        const { username, password, error } = this.state;

        const loading = this.props.loading || this.state.loading;

        return (
            <div style={styles.wrapper} className="position-fixed d-flex align-items-center justify-content-center">
                <div style={styles.loginBox} className="loginBox">
                    <div className="d-flex align-items-center justify-content-center">
                        <EvaIcon name="lock" outline style={styles.lockIcon}/>
                    </div>
                    <div className="card-body px-lg-5 py-lg-5">
                        <form>
                            <div className="form-group mb-3">
                                <div className="input-group input-group-alternative">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" style={loading ? styles.disabledPrepend : undefined}>
                                            <EvaIcon name="person" outline/>
                                        </span>
                                    </div>
                                    <Input
                                        placeholder={t('page.panel.login.usernamePlaceholder')}
                                        type="text"
                                        value={username}
                                        onChange={this.handle_usernameChange}
                                        disabled={loading}
                                        onKeyPress={this.handle_keyPress}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-alternative">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" style={loading ? styles.disabledPrepend : undefined}>
                                            <EvaIcon name="lock" outline/>
                                        </span>
                                    </div>
                                    <Input
                                        placeholder={t('page.panel.login.passwordPlaceholder')}
                                        type="password"
                                        value={password}
                                        onChange={this.handle_passwordChange}
                                        disabled={loading}
                                        onKeyPress={this.handle_keyPress}
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="btn btn-secondary my-4"
                                    onClick={this.handle_submit}
                                    disabled={loading}
                                >
                                    {t('page.panel.login.loginButtonLabel')}
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div className={'alert alert-danger invalidUsernameOrPasswordAlert' + (error ? ' shown' : '')} role="alert">
                        <span className="alert-inner--icon"><EvaIcon name="alert-triangle" outline style={styles.alertIcon}/></span>
                        <span className="alert-inner--text">{t('page.panel.login.invalidUsernameOrPasswordError')}</span>
                    </div>
                </div>
            </div>
        );
    }
}

LoginScreen.propTypes = {
    onLogin: PropTypes.func,
    loading: PropTypes.bool
};

const styles = {
    wrapper: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#f9f9f9'
    },
    loginBox: {
        width: 400,
        maxWidth: '100%'
    },
    lockIcon: {
        fontSize: 60,
        color: '#e2e2e2'
    },
    disabledPrepend: {
        backgroundColor: '#e9ecef',
        transition: 'all .2s cubic-bezier(.68,-.55,.265,1.55)'
    },
    alertIcon: {
        fontSize: 30,
        transform: 'translateY(4px)'
    }
};

export default withTranslation()(LoginScreen);