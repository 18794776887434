import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import EvaIcon from '../../components/EvaIcon';

import './MailConfirmationModal.scss';

class MailConfirmationModal extends Component {
    handleClose = () => {
        if (this.props.onClose) this.props.onClose();
    };

    render() {
        const { t } = this.props;
        const { open } = this.props;

        return (
            <Modal isOpen={open} toggle={this.handleClose} className="mailConfirmationModal">
                <ModalBody>
                    <div className="my-5 mb-2 d-flex justify-content-center align-items-center">
                        <EvaIcon name="alert-triangle" outline style={{ fontSize: 50 }}/>
                    </div>
                    <h4 className="mb-4">{t('page.voting.mailConfirmationModal.title')}</h4>
                    <span><Trans i18nKey='page.voting.mailConfirmationModal.description' /></span>
                </ModalBody>
                <ModalFooter className="Elect">
                    <Button className="custom-btn neutral" onClick={this.handleClose}>{t('page.voting.mailConfirmationModal.closeButtonLabel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

MailConfirmationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default withTranslation()(MailConfirmationModal);