import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import IconButton from '../../../../components/IconButton/IconButton';

class File extends Component {
    state = {
        lightboxOpen: false
    };

    render() {
        const { id, fileName, onDelete, placeholder } = this.props;
        const { lightboxOpen } = this.state;

        const imageUrl = '/api/images/' + id;

        return (
            <div className="d-flex align-items-center" style={{
                ...styles.row,
                ...(placeholder ? { opacity: 0.25 } : {})
            }}>
                <div className="d-flex align-items-center justify-content-center" style={styles.imageCell}>
                    <div className="d-flex" style={styles.imageContainer} onClick={() => this.setState({ lightboxOpen: true })}>
                        {!placeholder && (
                            <img
                                src={imageUrl}
                                style={styles.image}
                                onError={(event) => {event.target.style.display = 'none';}}
                                alt=""
                            />
                        )}
                    </div>
                </div>
                <div>
                    <span style={styles.fileName}>{fileName}</span>
                </div>
                <div>
                    <IconButton name="trash" outline onClick={onDelete} disabled={placeholder}/>
                </div>
                {lightboxOpen && (
                    <Lightbox
                        mainSrc={imageUrl}
                        onCloseRequest={() => this.setState({ lightboxOpen: false })}
                    />
                )}
            </div>
        );
    }
}

const styleConstants = {
    imageSize: 50
};

const styles = {
    row: {
        overflow: 'hidden'
    },
    imageCell: {
        width: styleConstants.imageSize + 20
    },
    imageContainer: {
        width: styleConstants.imageSize,
        height: styleConstants.imageSize,
        borderRadius: '50%',
        overflow: 'hidden',
        backgroundColor: '#e8e8e8',
        cursor: 'pointer'
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    },
    fileName: {
        textOverflow: 'ellipsis'
    }
};

File.propTypes = {
    id: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    placeholder: PropTypes.bool.isRequired
};

export default File;