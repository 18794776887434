const moment = require('moment'); //eslint-disable-line no-undef

const randomString = (length=8) => {
    let text = '';
    let possible = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

const emailValid = (email) => {
    //eslint-disable-next-line no-useless-escape
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const sortByField = (array, fieldName, lowerCase) => {
    return array.sort(function(a, b){
        let fa = a[fieldName];
        let fb = b[fieldName];

        if (lowerCase) {
            fa = fa.toLowerCase();
            fb = fb.toLowerCase();
        }

        if(fa < fb) return -1;
        if(fa > fb) return 1;
        return 0;
    });
};

const checkAuth = (req, res) => {
    if (!req.user) {
        res.status(401).json({ error: 'notAuthorized' });
        return true;
    }
    return false;
};

const round = (value, digits) => {
    const factor = Math.pow(10, digits);
    return Math.round(value * factor) / factor;
};

const votingEnabled = (settings) => {
    if (settings.votingEnabled !== '1') return false;
    if (settings.votingTimeframeEnabled !== '1') return true;

    const currentTimestamp = moment().unix();
    try {
        return (
            currentTimestamp >= parseInt(settings.votingTimeframeFrom) &&
            currentTimestamp <= parseInt(settings.votingTimeframeTo)
        );
    } catch (error) {
        return false;
    }
};

const sleep = (delay) => new Promise(resolve => setTimeout(resolve, delay));

const randomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const scrollToSmoothly = (pos, time) => {
    let currentPos = window.scrollY || window.screenTop;
    let start = null;
    time = time || 500;
    window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        if( currentPos < pos) {
            let progress = currentTime - start;
            window.scrollTo(0, ((pos-currentPos) * progress / time) + currentPos);
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        } else {
            let progress = currentTime - start;
            window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        }
    });
};

const scrollToDiv = (id, offset, time) => {
    let elem = document.querySelector(id);
    scrollToSmoothly(elem.offsetTop + offset, time);
};

//eslint-disable-next-line no-undef
module.exports = { randomString, emailValid, sortByField, checkAuth, round, votingEnabled, sleep, randomInt, scrollToSmoothly, scrollToDiv };