import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardImg, CardTitle, CardSubtitle, Col } from 'reactstrap';

import './Profile.scss';
class Profile extends Component {
    render() {
        const { elects, onLook, onSelect, selected } = this.props;

        return elects ? elects.map((elect, index) => (
            <Col xs="12" sm="6" md="4" xl="3" className="Profile root" key={index}>
                <Card data-checked={selected ? selected.id === elect.id : false} className="Profile">
                    <div className="Profile radioContainer">
                        <span data-checked={selected ? selected.id === elect.id : false} onClick={() => onSelect(elect)} className="Profile radio"/>
                    </div>
                    <CardImg onClick={() => onLook(elect)} src={elect.imageUrl} alt={elect.imageUrl} className="Profile"/>
                    <CardBody onClick={() => onLook(elect)} className="Profile">
                        <CardTitle className="Profile">{elect.name}</CardTitle>
                        <CardSubtitle className="Profile">{elect.activity}</CardSubtitle>
                    </CardBody>
                </Card>
            </Col>
        )) : '';
    }
}

Profile.propTypes = {
    onLook: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    elects: PropTypes.object,
    selected: PropTypes.bool
};

export default Profile;
