import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Api from '../../../../api/Api';
import { sortByField } from '../../../../Utils';
import ElectList from './ElectList/ElectList';
import CenteredLoader from '../../../../components/CenteredLoader/CenteredLoader';
import CreateElectModal from './CreateElectModal';
import ErrorModal from '../../../../components/ErrorModal';
import IconButton from '../../../../components/IconButton/IconButton';

class Elects extends Component {
    state = {
        modalOpen: false,
        error: false
    };

    constructor(props) {
        super(props);

        this.resetModal();
    }

    componentDidMount() {
        try {
            this.updateElects();
            this.updateImages();
        } catch (error) {
            this.setState({ error: true });
        }
    }

    updateElects = async () => {
        try {
            let elects = await Api.getElects();
            elects = sortByField(elects, 'name', true);
            this.setState({ elects });
        } catch (error) {
            this.setState({ error: true });
        }
    };

    updateImages = async () => {
        try {
            let images = await Api.getImages();
            this.setState({ images });
        } catch (error) {
            this.setState({ error: true });
        }
    };

    handle_modalToggle = () => this.setState({ modalOpen: !this.state.modalOpen });

    handle_modalSubmit = () => {
        const { name, activity, description, image } = this.state;

        if (!name) return;

        this.handle_modalToggle();

        Api.createElect(name, activity, description, image).then(() => {
            this.updateElects();
            this.setState({ error: false });
        }).catch(() => {
            this.setState({ error: true });
        });

        setTimeout(() => {
            this.resetModal();
        }, 1000);
    };

    handle_delete = async (id) => {
        try {
            await Api.deleteElect(id);
            this.updateElects();
            this.setState({ error: false });
        } catch (error) {
            this.setState({ error: false });
        }
    };

    handle_formElementChange = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    resetModal = () => {
        this.setState({
            name: undefined,
            activity: '',
            description: '',
            image: ''
        });
    };

    render() {
        const { t } = this.props;
        const { elects, images, modalOpen, error } = this.state;
        const { name, activity, description, image } = this.state;

        return (
            <div>
                <IconButton name="plus" onClick={this.handle_modalToggle} className="mb-2"/>

                <div className="row">
                    <div className="col-xs-12 col-xl-6">
                        <h4>{t('dict.elects')}</h4>
                        {elects !== undefined ? (
                            <ElectList
                                elects={elects}
                                images={images}
                                onDelete={this.handle_delete}
                                onUpdate={this.updateElects}
                                className="mb-3"
                            />
                        ) : (
                            <div className="d-flex align-items-center justify-content-center" style={{ width: '100%', height: 100 }}>
                                <CenteredLoader/>
                            </div>
                        )}
                    </div>
                </div>

                <CreateElectModal
                    open={modalOpen}
                    onCancel={this.handle_modalToggle}
                    onSubmit={this.handle_modalSubmit}
                    onChange={this.handle_formElementChange}
                    values={{ name, activity, description, image }}
                    images={images}
                />

                <ErrorModal descriptionKey="error.unexpected" open={error} onClose={() => this.setState({ error: false })}/>
            </div>
        );
    }
}

export default withTranslation()(Elects);