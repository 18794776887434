import React, { Component } from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';

import sketch from './sketch';

class AOA extends Component {

    render() {
        return (
            <div>
                <ReactP5Wrapper sketch={sketch}/>
            </div>
        );
    }
}

export default AOA;