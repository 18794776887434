import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './Sidebar.scss';

import { headerHeight, sidebarWidth } from '../LayoutConstants';
import EvaIcon from '../../../components/EvaIcon';
import IconButton from '../../../components/IconButton/IconButton';

class Sidebar extends Component {
    render() {
        const { t } = this.props;
        const { open, tabs, activeTab, user, onChange, onLogout } = this.props;

        return (
            <div
                style={{ ...styles.sidebar }}
                className={'sidebar d-flex flex-column' + (open ? ' open' : '')}
            >
                <div className="d-flex align-items-center pl-4" style={styles.title}>
                    <h4 className="m-0">{t('page.panel.header.title')}</h4>
                </div>
                <div className="d-flex align-items-center justify-content-between pl-3" style={styles.user}>
                    <div className="d-flex align-items-center">
                        <EvaIcon name="people" outline className="ml-1 mr-4"/>
                        <h6 className="m-0">{user.username}</h6>
                    </div>
                    <IconButton name="log-out" outline className="mr-3" onClick={onLogout}/>
                </div>
                <div style={styles.tabLabelContainer}>
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={'tabLabel d-flex align-items-center my-2 ' + (activeTab === index ? 'active' : '')}
                            style={styles.tabLabel}
                            onClick={() => onChange(index)}
                        >
                            <div className="d-flex justify-content-center align-items-center mr-4" style={styles.iconContainer}>
                                <EvaIcon name={tab.icon} style={styles.icon} outline/>
                            </div>
                            <h6 className="m-0" style={styles.tabLabelText}>{tab.name}</h6>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const styles = {
    sidebar: {
        position: 'fixed',
        top: 0,
        width: sidebarWidth,
        height: '100%',
        backgroundColor: '#ffffff',
        boxSizing: 'content-box',
        boxShadow: '10px 1px 30px rgba(0, 0, 0, 0.05)',
        zIndex: 3
    },
    title: {
        width: '100%',
        height: headerHeight,
        userSelect: 'none',
        borderBottom: '0.5px solid #efefef'
    },
    user: {
        height: 60,
        borderBottom: '0.5px solid #efefef'
    },
    tabLabelContainer: {
        width: '100%',
        boxSizing: 'padding-box'
    },
    tabLabel: {
        height: 46,
        cursor: 'pointer',
        overflow: 'hidden',
        color: '#7c7c7c',
        userSelect: 'none',
        borderRadius: '4px 0 0 4px'
    },
    iconContainer: {
        marginLeft: 34,
        width: 20
    },
    icon: {
        color: 'inherit',
        fontSize: 20
    },
    tabLabelText: {
        fontSize: 14,
        color: 'inherit',
        fontWeight: 'inherit'
    }
};

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    activeTab: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default withTranslation()(Sidebar);