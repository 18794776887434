import React, { Component } from 'react';
import { Button, Col, Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Parallax } from 'react-parallax';

import './Voting.scss';

import Api from '../../api/Api';
import Utils from '../../Utils';
import CenteredLoader from '../../components/CenteredLoader/CenteredLoader';
import Profile from './Profile';
import Break from './Break';
import Elect from './Elect';
import Vote from './Vote';
import MailConfirmationModal from './MailConfirmationModal';

class Voting extends Component {
    constructor(props) {
        super(props);

        let rand = Utils.randomInt(1, 5);
        let rand_bg = Utils.randomInt(1, 6);

        this.state = {
            elects: '',
            elect: '',
            r: null,
            openElect: false,
            openVote: false,
            openAlert: false,
            openAlertText: '',
            openAlertType: '',
            openMailConfirmationModal: false,
            votingEnabled: false,
            loaded: false,
            rand,
            rand_bg,
        };
    }

    componentDidMount() {
        Api.getSettings()
            .then((settings) => {
                let votingEnabled = Utils.votingEnabled(settings);
                this.setState({ votingEnabled, loaded: true });
                if(votingEnabled) {
                    Api.getElects()
                        .then((elects) => {
                            this.setState({
                                elects
                            });
                        })
                        .catch(() => {
                            let obj = { text: this.props.t('error.unexpected'), type: 'danger', duration: 3000 };
                            this.onAlert(obj);
                        });
                }
            })
            .catch(() => {
                let obj = { text: this.props.t('error.unexpected'), type: 'danger', duration: 3000 };
                this.onAlert(obj);
            });
    }

    onSelect = (elect) => {
        this.setState({ r: elect, openElect: false });
    };

    onVote = () => {
        if (this.state.r) this.setState({ openVote: true });
        else {
            let obj = { text: this.props.t('page.voting.error.noSelect'), type: 'danger', duration: 3000 };
            this.onAlert(obj);
        }
    };

    onVoted = () => {
        this.setState({ r: null });
        setTimeout(() => this.setState({ openMailConfirmationModal: true }), 250);
    };

    onAlert = (obj) => {
        this.setState({ openAlert: true, openAlertText: obj.text, openAlertType: obj.type });
        if (obj.duration !== 'off') {
            window.setTimeout(() => {
                this.setState({ openAlert: false });
            }, obj.duration);
        }
    };

    render() {
        const { t } = this.props;
        const { elects, elect, r, openElect, openVote, openAlert, openAlertText, openAlertType, openMailConfirmationModal, votingEnabled, loaded, rand, rand_bg } = this.state;

        return (
            <div className="Voting root">
                <Parallax
                    strength={500}
                    bgImage={'src/Voting/rand_bg/' + rand_bg + '.jpg'}
                    bgImageAlt="Background"
                    contentClassName="Voting parallaxContent"
                >
                    <div className="Voting titleContainer">
                        <div className="Voting title"> Sports Awards</div>
                        <div className="Voting scrollIndicator" onClick={() => Utils.scrollToDiv('#textBox', 10, 300)}>
                            <svg width="70" height="60" viewBox="-2.5 -5 55 70" preserveAspectRatio="none">
                                <path d="M0,0 l25,30 l25,-30" fill="none" stroke="white" strokeLinecap="round" strokeWidth="5" />
                            </svg>
                        </div>
                    </div>
                </Parallax>

                <div className="Voting textContainer" id="textBox">
                    <div className="Voting wrap container-fluid row justify-content-md-center">
                        <Col xs="12" md="6">
                            <h3>{t('page.voting.voting.infoTitle')}</h3>
                            <span>{t('page.voting.voting.infoText')}</span>
                        </Col>
                        <div className="d-flex align-items-center justify-content-center col col-12 col-md-6">
                            <img src="src/Voting/logo_kas.png" alt=""/>
                        </div>
                    </div>
                </div>

                <Parallax
                    strength={200}
                    bgImage={'src/Voting/rand/' + rand + '.jpg'}
                    bgImageAlt="Background"
                >
                    <div style={{ height: 500 }}/>
                </Parallax>

                <div className="Voting viewContainer">
                    <Col xs='12' className="Voting view">
                        { loaded ? (
                            votingEnabled ? (
                                elects ? (
                                    <div className="Voting profile">
                                        <p style={{ textAlign: 'center', color: '#00000073' }}>{t('page.voting.voting.infoSelection')}</p>
                                        <Break text={t('dict.elects')}/>
                                        <div className="Voting profilesContainerContainer">
                                            <div className="Voting profilesContainer">
                                                <Profile elects={elects} onLook={(elect) => this.setState({ elect, openElect: true })} onSelect={this.onSelect}
                                                    selected={r}/>
                                            </div>
                                        </div>
                                        <div className="Voting buttonContainer">
                                            <Button onClick={this.onVote}
                                                className="custom-btn primary">{t('page.voting.voting.vote')}</Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="Voting noVote">
                                        <CenteredLoader/>
                                    </div>
                                )
                            ) : (
                                <div className="Voting noVote">
                                    <h2>{t('page.voting.voting.noVote')}</h2>
                                </div>
                            )
                        ) : (
                            <div className="Voting noVote">
                                <CenteredLoader/>
                            </div>
                        )}
                    </Col>
                </div>

                <div className="Voting footerContainerContainer">
                    <div className="Voting footerContainer">
                        <Col xs="12" md="6">
                            <div className="Voting footerImgContainer">
                                <a href="https://ksschuepfheim.lu.ch/" target="_blank" rel="noopener noreferrer">
                                    <img src="src/Voting/logo_gymplus.png" alt="" className="Voting footerImg" />
                                </a>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="Voting footerImgContainer">
                                <a href="https://lukb.ch" target="_blank" rel="noopener noreferrer">
                                    <img src="src/Voting/logo_lukb.png" alt="" className="Voting footerImg" />
                                </a>
                            </div>
                        </Col>
                        <Col xs="12" md="12">
                            <div className="Voting footerTextContainer">
                                <a href="impressum">Impressum</a>
                            </div>
                        </Col>
                    </div>
                </div>

                <Elect
                    elect={elect}
                    open={openElect}
                    handleClose={() => this.setState({ openElect: false })}
                    onSelect={this.onSelect}
                />

                <Vote
                    vote={r}
                    open={openVote}
                    handleClose={() => this.setState({ openVote: false })}
                    onAlert={this.onAlert}
                    onVoted={this.onVoted}
                />

                <MailConfirmationModal open={openMailConfirmationModal} onClose={() => this.setState({ openMailConfirmationModal: false })}/>

                <div className="Voting alertContainer">
                    <Alert className="Voting alertFixed" color={openAlertType} isOpen={openAlert} toggle={() => this.setState({ openAlert: false })}>{openAlertText}</Alert>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Voting);