import React from 'react';
import PropTypes from 'prop-types';
import Turnstile from 'react-turnstile';

// TODO Safe to store client side, probably still better if it's in a ENV variable
const SITEKEY = '0x4AAAAAABBaqhGlVOkTjETh';

const CFTurnstile = ({ onVerify }) => <Turnstile sitekey={SITEKEY} onVerify={onVerify} theme="light"/>;

CFTurnstile.propTypes = {
    onVerify: PropTypes.func
};

export default CFTurnstile;
