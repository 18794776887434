import React, { Component } from 'react';

import './Impressum.scss';

class Impressum extends Component {

    spamProtection = (e) => {
        e.target.href = e.target.href.replace(/x/g, '').replace(/\[at\]/g, '@');
    };

    render() {
        return (
            <div className="impressum">
                <h1>Impressum</h1>
                <h3>Kontaktadresse</h3>
                <p>
                    {'Kantonsschule Schüpfheim / Gymnasium Plus\nLädergass 25\n6170 Schüpfheim\nSchweiz\nTelefon 041 485 80 20'}
                </p>
                <p><a href="mailto:xixnxfxox.xkxsxsxcxhx[at]xexdxuxlxux.xcxhx" onClick={this.spamProtection}>E-Mail</a></p>
                <br/>
                <h3>Vertretungsberechtigte Personen</h3>
                <p>Andreas Jöhl, Prorektor</p>
                <p><a href="mailto:xaxnxdxrxexaxsx.xjxoxexhxlx[at]xexdxuxlxux.xcxhx" onClick={this.spamProtection}>E-Mail</a></p>
                <br/>
                <h3>WebDesign</h3>
                <p>Jason Zibung</p>
                <p><a href="mailto:xaxdxmxixnx[at]xaxcxuxrxixsxux.xcxhx" onClick={this.spamProtection}>E-Mail</a></p>
                <p><a href="https://github.com/Acurisu" target="_blank" rel="noopener noreferrer" >GitHub</a></p>
                <br/>
                <p>Loris Sikora</p>
                <p><a href="mailto:xlxoxrxixsx.xsxixkxoxrxax[at]xhxoxtxmxaxixlx.xcxoxmx" onClick={this.spamProtection}>E-Mail</a></p>
                <p><a href="https://github.com/lorissikora" target="_blank" rel="noopener noreferrer" >GitHub</a></p>
                <br/>
                <h3>Haftungsausschluss</h3>
                <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
                    Zuverlässigkeit und Vollständigkeit der Informationen.</p>
                <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder
                    der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch
                    technische Störungen entstanden sind, werden ausgeschlossen.</p>
                <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte
                    Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
                    oder endgültig einzustellen.</p>
                <br/>
                <h3>Haftung für Links</h3>
                <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es wird jegliche
                    Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene
                    Gefahr des Nutzers oder der Nutzerin. </p>
                <br/>
                <h3>Urheberrechte</h3>
                <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website gehören
                    ausschliesslich <strong>der Firma Kantonsschule Schüpfheim / Gymnasium Plus</strong> oder den speziell genannten
                    Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger
                    im Voraus einzuholen.</p>
                <br/>
                <h3>Datenschutz</h3>
                <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des
                    Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor
                    Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche Daten werden streng
                    vertraulich behandelt und weder an Dritte verkauft noch weiter gegeben.</p>
                <p>In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor
                    fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.</p>
                <p>Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit,
                    Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser. Diese Nutzungsdaten bilden
                    die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind, anhand derer wir unsere
                    Angebote entsprechend verbessern können. </p>
            </div>
        );
    }
}

export default Impressum;
