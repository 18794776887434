import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import './Images.scss';

import Api from '../../../../api/Api';
import { sortByField } from '../../../../Utils';
import File from './File';
import CenteredLoader from '../../../../components/CenteredLoader/CenteredLoader';
import ErrorModal from '../../../../components/ErrorModal';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import IconButton from '../../../../components/IconButton/IconButton';

class Images extends Component {
    state = {
        fileTypeError: false,
        duplicateError: false,
        uploadingImages: {},
        deletingImages: {},
        deleteImage: undefined
    };

    componentDidMount() {
        this.update();
    }

    update = () => {
        Api.getImages().then((images) => {
            images = sortByField(images, 'fileName');
            this.setState({ images });
        }).catch(() => {
            this.setState({ unexpectedError: true });
        });
    };

    handle_upload = (event) => {        
        let files = event.target.files;
        if (!files.length === 0) return;

        let fileTypeError = false;
        for (let i = 0; i < files.length; i++) {
            if (!files[i].type.match('image/*')) fileTypeError = true;
        }

        if (fileTypeError) {
            this.setState({ fileTypeError: true });
            return;
        }

        const fileNameStateTrue = {};
        const fileNameStateFalse = {};
        for (let i = 0; i < files.length; i++) {
            fileNameStateTrue[files[i].name] = true;
            fileNameStateFalse[files[i].name] = false;
        }

        this.setState({
            uploadingImages: {
                ...this.state.uploadingImages,
                ...fileNameStateTrue
            }
        });

        Api.uploadImage(files).then(() => {
            this.setState({
                uploadingImages: {
                    ...this.state.uploadingImages,
                    ...fileNameStateFalse
                },
                fileTypeError: false,
                duplicateError: false
            });

            this.update();
        }).catch((error) => {
            let stateUpdates = {
                uploadingImages: {
                    ...this.state.uploadingImages,
                    ...fileNameStateFalse
                }
            };

            if (error.name === 'imageFileTypeNotSupported') {
                stateUpdates.fileTypeError = true;
            } else if (error.name === 'fileAlreadyExists') {
                stateUpdates.duplicateError = true;
            }

            this.setState(stateUpdates);
            this.update();
        });
    };

    handle_delete = async (id) => {
        this.setState({
            deletingImages: {
                ...this.state.deletingImages,
                [id]: true
            }
        });
        
        await Api.deleteImage(id);

        this.setState({
            deletingImages: {
                ...this.state.deletingImages,
                [id]: false
            }
        });
        this.update();
    };
    
    render() {
        const { t } = this.props;
        const { images, uploadingImages, deletingImages, unexpectedError, fileTypeError, duplicateError, deleteImage } = this.state;

        const uploading = Object.keys(uploadingImages).filter((key) => uploadingImages[key]).length > 0;

        let imageRows = (images ? images : []).map((image, i) => (
            <CSSTransition key={image.id} classNames="scale" style={{ transitionDelay: `${i * 1000}ms` }}>
                <File id={image.id} fileName={image.fileName} onDelete={() => this.setState({ deleteImage: image.id })} placeholder={deletingImages[image.id] === true}/>
            </CSSTransition>
        ));

        return (
            <div className="images" style={styles.container}>
                <form>
                    <div className="d-flex align-items-center">
                        <IconButton name="plus" onClick={(event) => {event.preventDefault(); document.getElementById('fileUpload').click();}} className="mb-2"/>
                        {uploading && <CenteredLoader style={{ width: 50 }}/>}
                    </div>
                    <input className="d-none" id="fileUpload" type="file" multiple onChange={this.handle_upload}/>
                </form>

                <div className="row ml-0 elevation-1" style={styles.imageContainer}>
                    <TransitionGroup className="transitionGroup" style={{ width: '100%' }}>
                        <div style={styles.tableHeaderRow}>
                            <div style={{ width: 70 }}/>
                            <span className="text-uppercase text-muted font-weight-bold" style={styles.tableHeader}>{t('page.panel.tab.images.tableHeader.name')}</span>
                        </div>
                        {imageRows}
                    </TransitionGroup>
                    {imageRows.length === 0 && (
                        <div className="d-flex justify-content-center py-4" style={{ width: '100%' }}>
                            <span className="text-muted">{images ? t('page.panel.tab.images.noImages') : <div style={{ height: 100 }}><CenteredLoader/></div>}</span>
                        </div>
                    )}
                </div>

                <ErrorModal descriptionKey="error.unexpected" open={unexpectedError} onClose={() => this.setState({ unexpectedError: false })}/>
                <ErrorModal descriptionKey="error.unsupportedFileType" open={fileTypeError} onClose={() => this.setState({ fileTypeError: false })}/>
                <ErrorModal descriptionKey="error.fileExists" open={duplicateError} onClose={() => this.setState({ duplicateError: false })}/>

                <ConfirmationModal
                    descriptionKey="page.panel.tab.images.confirmationModal"
                    open={deleteImage !== undefined}
                    onCancel={() => this.setState({ deleteImage: undefined })}
                    onSubmit={() => {this.handle_delete(deleteImage); this.setState({ deleteImage: undefined });}}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        width: '100%'
    },
    imageContainer: {
        width: '100%',
        padding: '10px 20px',
        backgroundColor: '#ffffff'
    },
    imageTileContainer: {
        maxWidth: 200,
        overflow: 'hidden'
    },
    tableHeaderRow: {
        borderBottom: '1px solid #ebebeb'
    },
    tableHeader: {
        fontSize: 12
    }
};

export default withTranslation()(Images);