import React, { Component } from 'react';

import Router from './router/Router';

class App extends Component {
    render() {
        return <Router/>;
    }
}

export default App;