import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './Content.scss';

import IconButton from '../../../components/IconButton/IconButton';

class Content extends Component {
    render() {
        const { sidebarOpen, tabs, activeTab } = this.props;

        const tab = tabs[activeTab];

        return (
            <div style={styles.content} className={'content' + (sidebarOpen ? ' sidebarOpen' : '')}>
                <div className="toggleSidebarButton align-content-center justify-content-center">
                    <IconButton name="menu" onClick={this.props.onSidebarOpen}/>
                </div>
                <h1 className="m-0">{tab.name}</h1>
                <hr className="m-0 mb-4"/>
                <TransitionGroup style={styles.transitionGroup}>
                    <CSSTransition key={activeTab} timeout={{ enter: 250, exit: 125 }} classNames="fade">
                        {tab.content}
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

const styles = {
    content: {
        minHeight: '100%',
        boxSizing: 'padding-box',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    transitionGroup: {
        width: '100%',
        position: 'relative'
    }
};

Content.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    activeTab: PropTypes.number.isRequired,
    onSidebarOpen: PropTypes.func.isRequired
};

export default Content;