import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import './Break.scss';

class Break extends Component {
    render() {
        const { text } = this.props;

        return (
            <div className="Break root">
                <Row>
                    <Col xs="4" className="Break breakLineCol">
                        <span className="Break breakLine"/>
                    </Col>
                    <Col xs="4" className="Break text"><h3>{text}</h3></Col>
                    <Col xs="4" className="Break breakLineCol">
                        <span className="Break breakLine"/>
                    </Col>
                </Row>
            </div>
        );
    }
}

Break.propTypes = {
    text: PropTypes.string.isRequired
};

export default Break;