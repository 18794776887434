import React, { Component } from 'react';
import { TransitionGroup } from 'react-transition-group';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import './RecentVotes.scss';

import Api from '../../../../api/Api';
import IconButton from '../../../../components/IconButton/IconButton';
import CenteredLoader from '../../../../components/CenteredLoader/CenteredLoader';
import ErrorModal from '../../../../components/ErrorModal';

class RecentVotes extends Component {
    state = {
        error: false
    };

    componentDidMount() {
        this.update();
    }

    update = async () => {
        try {
            Promise.all([
                Api.getElects(),
                Api.getRecentVotes()
            ]).then((results) => {
                let [elects, votes] = results;

                elects = elects.reduce((obj, current) => {
                    return {
                        ...obj,
                        [current.id]: current
                    };
                }, {});

                this.setState({
                    elects,
                    votes,
                    error: false
                });
            }).catch(() => {
                this.setState({ error: true });
            });
        } catch (error) {
            this.setState({ error: true });
        }
    };

    render() {
        const { t } = this.props;
        const { error, votes, elects } = this.state;

        return (
            <div className="recentVotes">
                <IconButton name="refresh" onClick={this.update} className="mb-2"/>
                <div className="elevation-1" style={styles.imageContainer}>
                    <table style={styles.table}>
                        <TransitionGroup component={'tbody'}>
                            <tr style={styles.tableHeaderRow}>
                                {['name', 'email', 'vote', 'timeDate'].map((label, index) => (
                                    <th className="text-uppercase text-muted font-weight-bold" style={styles.tableHeader} key={index}>{t(`page.panel.tab.recentVotes.tableHeader.${label}`)}</th>
                                ))}
                            </tr>
                            {votes && votes.map((vote) => {
                                const elect = elects[vote.vote];
                                const electName = elect ? elect.name : t('error.noName');

                                const time = moment(vote.timestamp);

                                return (
                                    <tr style={styles.tableRow} key={vote.id}>
                                        <td>{vote.name}</td>
                                        <td>{vote.email}</td>
                                        <td>{electName}</td>
                                        <td>{time.format('DD.MM.YY HH:mm')}</td>
                                    </tr>
                                );
                            })}
                        </TransitionGroup>
                    </table>

                    {!votes && (
                        <div style={{ width: '100%', height: 100 }}>
                            <CenteredLoader/>
                        </div>
                    )}
                    {votes && votes.length === 0 && (
                        <div className="d-flex align-items-center justify-content-center" style={{ width: '100%', height: 100 }}>
                            <span className="text-muted">{t('page.panel.tab.recentVotes.noVotes')}</span>
                        </div>
                    )}
                </div>

                <ErrorModal descriptionKey="error.unexpected" open={error} onClose={() => this.setState({ error: false })}/>
            </div>
        );
    }
}

const styles = {
    imageContainer: {
        width: '100%',
        padding: '10px 20px',
        overflowX: 'hidden',
        backgroundColor: '#ffffff'
    },
    table: {
        width: '100%'
    },
    tableHeader: {
        fontSize: 12
    },
    tableRow: {
        height: 50
    }
};

export default withTranslation()(RecentVotes);