import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './CenteredLoader.scss';

class CenteredLoader extends Component {
    render() {
        const style = { ...styles.container, ...(this.props.style ? this.props.style : {}) };
        const className = 'centeredLoader d-flex align-items-center justify-content-center' +
            (this.props.className ? ' ' + this.props.className : '');
        
        return (
            <div style={style} className={className}>
                <div style={styles.spinner}/>
            </div>
        );
    }
}

const styles = {
    container: {
        width: '100%',
        height: '100%'
    },
    spinner: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: '#d8d8d8 transparent transparent transparent'
    }
};

CenteredLoader.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string
};

export default CenteredLoader;