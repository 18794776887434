import React from 'react';
import PropTypes from 'prop-types';

const EvaIcon = ({ name, outline, style, className, onClick }) => (
    <i className={`eva eva-${name}${outline ? '-outline' : ''} ${className ? className : ''}`} style={style} onClick={onClick}/>
);

EvaIcon.propTypes = {
    name: PropTypes.string.isRequired,
    outline: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default EvaIcon;