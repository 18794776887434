import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Api from '../../../../api/Api';
import CenteredLoader from '../../../../components/CenteredLoader/CenteredLoader';
import IconButton from '../../../../components/IconButton/IconButton';
import VotingPieChart from './VotingPieChart';
import RecentVotesChart from './RecentVotesChart';
import ErrorModal from '../../../../components/ErrorModal';

class Statistics extends Component {
    state = {
        error: false,
        recentVotesFilter: null
    };

    componentDidMount() {
        this.updateSummary();
        this.updateRecentVotes();
    }

    updateSummary = async () => {
        try {
            const summary = await Api.getStatisticsSummary();
            let total = Object.keys(summary.votes).reduce((sum, currentId) => sum + summary.votes[currentId], 0);
            this.setState({ summary, total });
        } catch (error) {
            this.setState({ error: true });
        }
    };

    updateRecentVotes = async () => {
        const { recentVotesFilter } = this.state;

        try {
            const recentVotes = await Api.getStatisticsRecentVotes(recentVotesFilter);
            this.setState({ recentVotes });
        } catch (error) {
            this.setState({ error: true });
        }
    };

    handle_recentVotesFilterChange = (electId) => this.setState({ recentVotesFilter: electId }, this.updateRecentVotes);

    render() {
        const { t } = this.props;
        const { summary, total, recentVotes, error } = this.state;

        return (
            <div>
                {(summary && recentVotes) ? (
                    <React.Fragment>
                        <div className="d-flex">
                            <h3>{t('page.panel.tab.statistics.votes.label')}</h3>
                            <IconButton name="refresh" onClick={this.update} className="ml-2"/>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xl-6 mb-3">
                                <VotingPieChart
                                    elects={summary.elects}
                                    statistics={summary.votes}
                                    style={styles.chart}
                                />
                            </div>
                        </div>
                        <h4 className="mb-0">{t('page.panel.tab.statistics.votes.total')}</h4>
                        <h5 className="mt-0 ml-3">{total}</h5>
                        <hr/>
                        <h3>{t('page.panel.tab.statistics.recentVotes.label')}</h3>
                        <RecentVotesChart
                            elects={summary.elects}
                            statistics={recentVotes}
                            onFilterChange={this.handle_recentVotesFilterChange}
                            style={styles.chart}
                        />
                    </React.Fragment>
                ) : (
                    <div style={{ width: '100%', height: 100 }}>
                        <CenteredLoader/>
                    </div>
                )}

                <ErrorModal descriptionKey="error.unexpected" open={error} onClose={() => this.setState({ error: false })}/>
            </div>
        );
    }
}

const styles = {
    chart: {
        maxWidth: 700
    }
};

export default withTranslation()(Statistics);