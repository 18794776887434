import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Input, ModalFooter, Button } from 'reactstrap';
import EvaIcon from '../../../../components/EvaIcon';
import { withTranslation } from 'react-i18next';

class CreateElectModal extends Component {
    handle_cancel = () => {
        const { onCancel } = this.props;
        if (onCancel) onCancel();
    };

    handle_submit = () => {
        const { onSubmit } = this.props;
        if (onSubmit) onSubmit();
    };

    handle_change = (name, value) => {
        const { onChange } = this.props;
        if (onChange) onChange(name, value);
    };

    render() {
        const { t } = this.props;
        const { open, values, images } = this.props;

        return (
            <Modal isOpen={open} toggle={this.handle_cancel}>
                <ModalHeader toggle={this.handle_modalToggle}>{t('page.panel.tab.elects.modal.label')}</ModalHeader>
                <ModalBody>
                    <CreateElectModalContent
                        values={values}
                        onChange={this.handle_change}
                        images={images}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handle_cancel}>{t('page.panel.tab.elects.modal.cancelButtonLabel')}</Button>
                    <Button color="primary" onClick={this.handle_submit}>{t('page.panel.tab.elects.modal.submitButtonLabel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

CreateElectModal.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    values: PropTypes.object.isRequired,
    images: PropTypes.array,
};

export default withTranslation()(CreateElectModal);

class _CreateElectModalContent extends Component {
    handle_change = (name) => (value) => {
        const { onChange } = this.props;
        if (onChange) onChange(name, value);
    };

    render() {
        const { t } = this.props;
        const { values, images } = this.props;

        return (
            <div>
                <CreateElectModalComponent name="name" value={values.name} onChange={this.handle_change('name')} type="text" iconName="person" iconOutline/>
                <CreateElectModalComponent name="activity" value={values.activity} onChange={this.handle_change('activity')} type="text" iconName="trending-up" iconOutline/>
                <CreateElectModalComponent name="description" value={values.description} onChange={this.handle_change('description')} type="textarea" iconName="menu" iconOutline inputProps={{ style: { minHeight: 75, maxHeight: 250 } }}/>
                <CreateElectModalComponent name="image" value={values.image} onChange={this.handle_change('image')} type="select" iconName="image" iconOutline>
                    <option value="">{t('page.panel.tab.elects.noImageSelected')}</option>
                    {images && images.map((image, index) => (
                        <option value={image.id} key={index}>{image.fileName}</option>
                    ))}
                </CreateElectModalComponent>
            </div>
        );
    }
}

_CreateElectModalContent.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    images: PropTypes.array
};

const CreateElectModalContent = withTranslation()(_CreateElectModalContent);

class _CreateElectModalComponent extends Component {
    handle_change = (e) => {
        const { onChange } = this.props;
        if (onChange) onChange(e.target.value);
    };

    render() {
        const { t } = this.props;
        const { name, value, type, iconName, iconOutline, children, inputProps } = this.props;

        return (
            <div>
                <span className="text-uppercase font-weight-bold">{t('page.panel.tab.elects.field.' + name)}</span>
                <div className="input-group input-group-alternative mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><EvaIcon name={iconName} outline={iconOutline}/></span>
                    </div>
                    <Input {...inputProps} placeholder={t('page.panel.tab.elects.field.' + name)} type={type} value={value} onChange={this.handle_change}>
                        {children}
                    </Input>
                </div>
            </div>
        );
    }
}

_CreateElectModalComponent.propTypes = {
    children: PropTypes.array,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    type: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    iconOutline: PropTypes.bool,
    inputProps: PropTypes.object
};

const CreateElectModalComponent = withTranslation()(_CreateElectModalComponent);

export { CreateElectModalContent, CreateElectModalComponent };