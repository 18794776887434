import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import de_CH from './locales/de_CH/common.json';

const lang = 'de';
import ('moment/locale/' + lang); //eslint-disable-line no-unused-expressions

i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                translation: de_CH
            }
        },
        lng: lang,
        fallbackLng: lang,
        interpolation: {
            escapeValue: false
        }
    });

moment.locale(lang);
