import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import Api from '../../../../../api/Api';
import { CreateElectModalContent } from '../CreateElectModal';

class ElectSettings extends Component {
    constructor(props) {
        super(props);

        const { name, activity, description, image } = props.elect;
        this.state = { name, activity, description, image, loading: false, error: false };
    }

    reset = () => {
        const { name, activity, description, image } = this.props.elect;
        this.setState({ name, activity, description, image });

        this.props.onReset();
    };

    update = async () => {
        const { elect, onUpdate } = this.props;
        const { name, activity, description, image } = this.state;

        const updates = { name, activity, description, image };

        this.setState({ loading: true });

        try {
            await Api.updateElect(elect.id, updates);
            onUpdate();
        } finally {
            this.setState({ loading: false });
        }
    };

    handle_formElementChange = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    render() {
        const { t, images } = this.props;
        const { name, activity, description, image } = this.state;

        return (
            <div className="container p-3">
                <div>
                    <CreateElectModalContent
                        values={{ name, activity, description, image }}
                        onChange={this.handle_formElementChange}
                        images={images}
                    />
                </div>
                <div className="mt-3">
                    <Button color="secondary" onClick={this.reset}>{t('page.panel.tab.elects.settings.cancelButtonLabel')}</Button>
                    <Button color="primary" onClick={this.update}>{t('page.panel.tab.elects.settings.submitButtonLabel')}</Button>
                </div>
            </div>
        );
    }
}

ElectSettings.propTypes = {
    elect: PropTypes.object.isRequired,
    images: PropTypes.array,
    onUpdate: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired
};

export default withTranslation()(ElectSettings);