import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import IconButton from '../../../../../components/IconButton/IconButton';
import './ElectList.scss';
import ElectSettings from './ElectSettings';
import ConfirmationModal from '../../../../../components/ConfirmationModal';

class ElectList extends Component {
    state = {
        activeElect: -1,
        deleteElect: undefined
    };

    handle_delete = (id) => {
        setTimeout(() => this.handle_electSelect(-1));
        this.props.onDelete(id);
    };

    handle_close = () => setTimeout(() => this.handle_electSelect(-1));

    handle_electSelect = (i) => this.setState({ activeElect: i });

    render() {
        const { t, elects, images, className, onUpdate } = this.props;
        const { activeElect, deleteElect } = this.state;

        return (
            <div className={'electList ' + (className ? className : '')} style={styles.container}>
                {elects.map((elect, index) => (
                    <div
                        key={elect.id}
                        style={styles.elect}
                        className={'elect ' + (index === activeElect ? 'active' : '')}
                    >
                        <div
                            className="electHeader d-flex align-items-center px-3 justify-content-between"
                            style={styles.electHeader}
                            onClick={() => {
                                if (elect.id !== activeElect) this.handle_electSelect(index);
                            }}
                        >
                            <span>{elect.name ? elect.name : t('error.noName')}</span>
                            <div className="d-flex">
                                <IconButton name="trash" outline className="deleteButton" style={styles.electIconButtonDelete} onClick={() => this.setState({ deleteElect: elect.id })}/>
                                <IconButton name="close" className="closeButton" style={styles.electIconButtonClose} onClick={this.handle_close}/>
                            </div>
                        </div>
                        <div className="p-2" style={styles.electContent}>
                            <ElectSettings elect={elect} images={images} onUpdate={() => {onUpdate(); this.handle_electSelect(-1);}} onReset={() => this.handle_electSelect(-1)}/>
                        </div>
                    </div>
                ))}
                {elects.length === 0 && (
                    <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
                        <span className="text-muted">{t('page.panel.tab.elects.noElects')}</span>
                    </div>
                )}

                <ConfirmationModal
                    descriptionKey="page.panel.tab.elects.confirmationModal"
                    open={deleteElect !== undefined}
                    onCancel={() => this.setState({ deleteElect: undefined })}
                    onSubmit={() => {this.handle_delete(deleteElect); this.setState({ deleteElect: undefined });}}
                />
            </div>
        );
    }
}

ElectList.propTypes = {
    elects: PropTypes.array.isRequired,
    images: PropTypes.array,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    className: PropTypes.string
};

const styles = {
    container: {
        width: '100%'
    },
    elect: {
        width: '100%',
        overflow: 'hidden'
    },
    electHeader: {
        height: 50,
        userSelect: 'none'
    },
    electContent: {
        overflow: 'hidden auto',
        maxHeight: 600
    },
    electIconButtonClose: {
        fontSize: 30,
        cursor: 'pointer'
    },
    electIconButtonDelete: {
        fontSize: 25,
        cursor: 'pointer'
    }
};

export default withTranslation()(ElectList);
