import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Input } from 'reactstrap';

import { sortByField } from '../../../../Utils';

class RecentVotesChart extends Component {
    state = {
        filter: null
    };

    handle_filterChange = (e) => {
        const value = e.target.value;

        if (this.props.onFilterChange) this.props.onFilterChange(value >= 0 ? value : undefined);
        this.setState({ filter: value });
    };

    render() {
        const { t } = this.props;
        let { elects, statistics } = this.props;

        const data = {
            labels: [],
            datasets: [
                {
                    label: t('page.panel.tab.statistics.recentVotes.chartLabel'),
                    data: [],
                    backgroundColor: 'rgba(161, 184, 246, 0.25)',
                    borderColor: '#5d85ee'
                }
            ]
        };
    
        Object.keys(statistics).forEach((timestamp) => {
            const count = statistics[timestamp];

            const timeString = moment.unix(parseInt(timestamp)).format('dd DD.MM');

            data.labels.push(timeString);
            data.datasets[0].data.push(count);
        });

        const max = Object.values(statistics).reduce((current, max) => current > max ? current : max, 0);

        const electsSorted = sortByField(elects, 'name', true);

        return (
            <div style={this.props.style}>
                <div className="input-group input-group-alternative mb-3">
                    <Input type="select" onChange={this.handle_filterChange}>
                        <option value={-1}>{t('page.panel.tab.statistics.recentVotes.filter.all')}</option>
                        {electsSorted.map((elect) => (
                            <option value={elect.id} key={elect.id}>{elect.name}</option>
                        ))}
                    </Input>
                </div>
                <Line
                    data={data}
                    options={{
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    max: max + 1,
                                    min: 0,
                                    maxTicksLimit: 10,
                                    precision: 0
                                }
                            }]
                        },
                    }}
                />
            </div>
        );
    }
}

RecentVotesChart.propTypes = {
    elects: PropTypes.object.isRequired,
    statistics: PropTypes.object,
    onFilterChange: PropTypes.func,
    style: PropTypes.object
};

export default withTranslation()(RecentVotesChart);