import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './Panel.scss';

import Api from '../../../api/Api';
import LoginScreen from '../LoginScreen/LoginScreen';
import Sidebar from '../Sidebar/Sidebar';
import Content from '../Content/Content';

import Elects from '../Tabs/Elects/Elects';
import Images from '../Tabs/Images/Images';
import RecentVotes from '../Tabs/RecentVotes/RecentVotes';
import Statistics from '../Tabs/Statistics/Statistics';
import Settings from '../Tabs/Settings/Settings';


class Panel extends Component {
    state = {
        sidebarOpen: false,
        fixed: true,
        activeTab: 0
    };

    constructor(props) {
        super(props);

        const { t } = props;

        this.tabs = [
            { name: t('page.panel.tabLabel.elects'), icon: 'person', content: <Elects/> },
            { name: t('page.panel.tabLabel.images'), icon: 'image', content: <Images/> },
            { name: t('page.panel.tabLabel.recentVotes'), icon: 'bar-chart', content: <RecentVotes/> },
            { name: t('page.panel.tabLabel.statistics'), icon: 'pie-chart', content: <Statistics/> },
            { name: t('page.panel.tabLabel.settings'), icon: 'settings', content: <Settings/> }
        ];

        window.onresize = this.handle_windowResize;
    }

    handle_windowResize = () => {
        const width = window.innerWidth;
        if (width >= 750 && !this.state.fixed) {
            this.setState({ fixed: true, sidebarOpen: true });
        } else if (width < 750 && this.state.fixed) {
            this.setState({ fixed: false, sidebarOpen: false });
        }
    };

    async componentDidMount() {
        const { user } = await Api.getCurrentUser();
        this.setState({ user });

        this.handle_windowResize();
    }

    toggleSidebar = () => this.setState({ sidebarOpen: !this.state.sidebarOpen });

    handle_change = (index) => {
        this.setState({ activeTab: index, sidebarOpen: false });
    };

    handle_login = (user) => this.setState({ user });

    handle_logout = async () => {
        await Api.logout();
        this.setState({ user: null });
    };

    render() {
        const { sidebarOpen, fixed, activeTab, user } = this.state;

        const tabs = this.tabs;

        return (
            <TransitionGroup>
                {user === undefined || user === null ? (
                    <CSSTransition
                        key={0}
                        timeout={{ enter: 700, exit: 250 }}
                        classNames="loginTransition_login"
                    >
                        <LoginScreen
                            onLogin={this.handle_login}
                            loading={user === undefined}
                        />
                    </CSSTransition>
                ) : (
                    <CSSTransition
                        key={1}
                        timeout={{ enter: 700, exit: 250 }}
                        classNames="loginTransition"
                    >
                        <div className="panel" style={styles.panel}>
                            <Sidebar
                                {...{ open: sidebarOpen || fixed, tabs, activeTab }}
                                user={user}
                                onChange={this.handle_change}
                                onLogout={this.handle_logout}
                            />
                            <Content {...{ sidebarOpen: fixed, tabs, activeTab, onSidebarOpen: this.toggleSidebar }}/>
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
        );
    }
}

const styles = {
    panel: {
        backgroundColor: '#f9f9f9',
        width: '100vw',
        minHeight: '100vh',
        overflow: 'hidden'
    }
};

export default withTranslation()(Panel);