import axios from 'axios';

function apiPromise(method, url, postParameters, headers) {
    return new Promise((resolve, reject) => {
        let object = {
            method,
            url: '/api/' + url
        };

        if (headers) object.headers = headers;
    
        if (postParameters)
            object.data = postParameters;

        axios(object).then((result) => {
            resolve(result.data);
        }).catch((error) => {
            if (error.response.status === 401) {
                window.location.reload();
                return;
            }
            reject({
                status: error.response.status,
                name: error.response.data.error
            });
        });
    });
}

class Api {
    static getElects = () => apiPromise('get', 'elects');
    static getElect = (id) => apiPromise('get', 'elects/' + id);
    static createElect = (name, activity, description, image) => apiPromise('post', 'elects', { name, activity, description, image });
    static updateElect = (id, updates) => apiPromise('put', 'elects/' + id, updates);
    static deleteElect = (id) => apiPromise('delete', 'elects/' + id);
    static vote = (name, email, vote, turnstileToken) => apiPromise('put', 'vote', { name, email, vote, turnstileToken });
    static getRecentVotes = () => apiPromise('get', 'recentVotes');
    static getPendingVote = (token) => apiPromise('get', 'confirm/' + token);
    static confirmVote = (token) => apiPromise('put', 'confirm/' + token);
    static deletePendingVote = (token) => apiPromise('delete', 'confirm/' + token);
    static getStatisticsSummary = () => apiPromise('get', 'statistics/summary');
    static getStatisticsRecentVotes = (recentVotesFilter) => apiPromise('get', 'statistics/recentVotes/' + (recentVotesFilter ? recentVotesFilter : ''));
    static getImages = () => apiPromise('get', 'images');
    static getImage = (id) => apiPromise('get', 'images/' + id);
    static uploadImage = (files) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }

        return apiPromise('post', 'images', formData, {
            'Content-Type': 'multipart/form-data'
        });
    };
    static deleteImage = (id) => apiPromise('delete', 'images/' + id);
    static reset = () => apiPromise('delete', 'reset');
    static getCurrentUser = () => apiPromise('get', 'auth');
    static login = (username, password) => apiPromise('post', 'auth', { username, password });
    static logout = () => apiPromise('delete', 'auth');
    static getSettings = () => apiPromise('get', 'settings');
    static updateSettings = (updates) => apiPromise('put', 'settings', { updates });
}

export default Api;