export default function Particle(p, location) {
    this.location = location.copy();
    this.velocity = p.createVector(p.random(0, 5), p.random(0, 5));
    this.acceleration = p.createVector(0,0);

    this.show = function() {
        p.stroke(0);
        p.strokeWeight(4);
        p.point(this.location.x, this.location.y);
    };

    this.update = function() {
        this.velocity.add(this.acceleration);
        this.location.add(this.velocity);

        if (this.location.x < 0) {
            this.location.x = p.width;
            this.velocity = p.createVector(p.random(0, 3), p.random(0, 3));
        } else if (this.location.x > p.width) {
            this.location.x = 0;
            this.velocity = p.createVector(p.random(0, 3), p.random(0, 3));
        }

        if (this.location.y < 0) {
            this.location.y = p.height;
            this.velocity = p.createVector(p.random(0, 3), p.random(0, 3));
        } else if (this.location.y > p.height) {
            this.location.y = 0;
            this.velocity = p.createVector(p.random(0, 3), p.random(0, 3));
        }
    };

    this.run = function() {
        this.update();
        this.show();
    };
}
