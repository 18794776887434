import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';

import './Elect.scss';

class Elect extends Component {
    imgLoaded = () => {
        let modal = document.getElementsByClassName('modal')[0];
        setTimeout(() => modal.classList.add('scroll-touch'), 500);
    };

    render() {
        const { t } = this.props;
        const { elect, open, handleClose, onSelect } = this.props;

        return (
            <Modal isOpen={open} toggle={handleClose}>
                <div className="modal-header">
                    <h5 className="Elect modal-title">{elect && elect.name}</h5>
                    <button type="button" className="Elect close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody className="d-flex justify-content-center align-items-center">
                    {
                        elect &&
                        (
                            <Col xs="12">
                                <Row className="Elect body">
                                    <img onLoad={this.imgLoaded} className="Elect img" src={elect.imageUrl} alt={elect.imageUrl} />
                                </Row>
                                <Row className="Elect body">
                                    <span className="Elect subtitle">{elect.activity}</span>
                                </Row>
                                <Row className="Elect body">
                                    <span className="Elect description">{elect.description}</span>
                                </Row>
                            </Col>
                        )
                    }
                </ModalBody>
                <ModalFooter className="Elect">
                    <Button className="custom-btn neutral" onClick={handleClose}>{t('page.voting.elect.cancel')}</Button>
                    <Button className="custom-btn primary" onClick={() => onSelect(elect)}>{t('page.voting.elect.select')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

Elect.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    elect: PropTypes.object
};

export default withTranslation()(Elect);
