import Particle from './particles';

export default function sketch(p) {
    // Globals
    let systems;

    // Parameters
    let total = p.windowWidth >= 720 ? 25 : 8;

    p.setup = function() {
        p.frameRate(30);

        p.createCanvas(p.windowWidth, p.windowHeight);

        systems = [];
        for (let i = 0; i < total; i++){
            systems.push(new Particle(p, p.createVector(p.floor(p.random(p.width)), p.floor(p.random(p.height)))));
        }
    };

    p.draw = function() {
        p.background(255);

        p.fill(0);
        p.textFont('Nunito');
        p.textAlign(p.CENTER);

        p.textSize(100);
        p.text('404', p.windowWidth / 2, p.windowHeight / 2);
        p.textSize(50);
        p.text('Page not found', p.windowWidth / 2, p.windowHeight / 2 + 50);

        for (let i = 0; i < systems.length; i++) {
            systems[i].show();

            for (let j = 0; j < systems.length; j++) {
                let distance = p.dist(systems[i].location.x, systems[i].location.y, systems[j].location.x, systems[j].location.y);
                p.strokeWeight(0.5);

                if (distance < 200) {
                    // let lineAlpha = p.map(distance, 0, 0, 0, 0);
                    p.stroke(0, 0, 0, 50);
                    p.line(systems[i].location.x, systems[i].location.y, systems[j].location.x, systems[j].location.y);
                }
            }
            systems[i].update();
        }
    };
}