import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback, Col } from 'reactstrap';

import Utils from '../../Utils';
import Api from '../../api/Api';

import './Vote.scss';
import CFTurnstile from '../../components/CFTurnstile';

class Vote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            nameValid: true,
            emailValid: true,
            disabled: false,
            turnstileToken: undefined
        };
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, [event.target.name + 'Valid']: true });
    };

    onVote = () => {
        let emailValid = Utils.emailValid(this.state.email);
        let nameValid = !!this.state.name;
        if (!emailValid || !nameValid) {
            this.setState({ emailValid: emailValid, nameValid: nameValid });
        } else {
            this.setState({ disabled: true });

            Api.vote(this.state.name, this.state.email, parseInt(this.props.vote.id), this.state.turnstileToken).then(() => {
                this.setState({ disabled: false });
                this.props.onVoted();
                this.props.handleClose();
            }).catch((error) => {
                this.setState({ disabled: false });
                this.props.handleClose();
                let obj = { text: '', type: 'danger', duration: 3000 };
                obj.text = error.name === 'alreadyVoted' ? this.props.t('page.voting.error.alreadyVoted') : this.props.t('error.unexpected');
                this.props.onAlert(obj);
            });
        }
    };

    onKeyPress = (event) => {
        if (event.key === 'Enter') this.onVote();
    };

    render() {
        const { t } = this.props;
        const { vote, open, handleClose } = this.props;

        return (
            <Modal isOpen={open} toggle={handleClose}>
                <div className="modal-header">
                    <h5 className="Elect modal-title">{t('page.voting.vote.title')}</h5>
                    <button type="button" className="Elect close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody className="d-flex justify-content-center align-items-center">
                    <Col md="10">
                        <Form>
                            <FormGroup>
                                <Label for="name">{t('page.voting.vote.name')}</Label>
                                <Input onKeyPress={this.onKeyPress} onChange={this.onChange} type="text" name="name" id="name" placeholder={t('page.voting.vote.nameExample')} disabled={this.state.disabled} invalid={!this.state.nameValid}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">{t('page.voting.vote.email')}</Label>
                                <Input onKeyPress={this.onKeyPress} onChange={this.onChange} type="text" name="email" id="email" placeholder={t('page.voting.vote.emailExample')} disabled={this.state.disabled} invalid={!this.state.emailValid}/>
                                <FormFeedback tooltip>{t('page.voting.error.noEmail')}</FormFeedback>
                            </FormGroup>
                        </Form>
                        <p style={{ fontSize: 12, lineHeight: 1 }}>{t('page.voting.voting.infoEmail')}</p>
                        <div className="Vote container">
                            <div className="Vote data">
                                <img className="Vote img" src={vote && vote.imageUrl} alt={vote && vote.imageUrl}/>
                                <span>{vote && vote.name}</span>
                            </div>
                        </div>
                        
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <CFTurnstile onVerify={token => this.setState({ turnstileToken: token })}/>
                        </div>
                    </Col>
                </ModalBody>
                <ModalFooter className="Elect">
                    <Button className="custom-btn neutral" onClick={handleClose}>{t('ui.confirmationModal.cancelButtonLabel')}</Button>
                    <Button className="custom-btn primary" onClick={this.onVote} disabled={this.state.disabled || this.state.turnstileToken === undefined}>{t('ui.confirmationModal.submitButtonLabel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

Vote.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onVoted: PropTypes.func.isRequired,
    onAlert: PropTypes.func.isRequired,
    vote: PropTypes.object
};

export default withTranslation()(Vote);
