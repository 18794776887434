import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';

import { sortByField, round } from '../../../../Utils';

const minColor = {
    red: 85,
    green: 129,
    blue: 246
};

const maxColor = {
    red: 229,
    green: 236,
    blue: 255
};

class VotingPieChart extends Component {
    render() {
        const { t } = this.props;
        const elects = sortByField(this.props.elects, 'name', true);
        const { statistics } = this.props;

        let data = elects.reduce((obj, current, index) => {
            let color = {
                red: Math.round(minColor.red + (index / elects.length) * (maxColor.red - minColor.red)),
                green: Math.round(minColor.green + (index / elects.length) * (maxColor.green - minColor.green)),
                blue: Math.round(minColor.blue + (index / elects.length) * (maxColor.blue - minColor.blue))
            };

            return {
                ...obj,
                labels: [
                    ...obj.labels,
                    current.name
                ],
                datasets: [
                    {
                        ...obj.datasets[0],
                        data: [
                            ...obj.datasets[0].data,
                            statistics[current.id]
                        ],
                        backgroundColor: [
                            ...obj.datasets[0].backgroundColor,
                            `rgb(${color.red}, ${color.green}, ${color.blue})`
                        ]
                    }
                ]
            };
        }, {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }]
        });

        const sortedStatistics = Object.entries(statistics).sort((a, b) => {
            if (a[1] < b[1]) return 1;
            if (a[1] > b[1]) return -1;
            return 0;
        });
        const totalVotes = Object.values(statistics).reduce((sum, current) => sum + current, 0);

        const electNames = elects.reduce((obj, current) => ({ ...obj, [current.id]: current.name }), {});

        return (
            <div
                style={this.props.style}
                className={this.props.className}
            >
                {elects.length > 0 ? (
                    <React.Fragment>
                        <Doughnut
                            data={data}
                            legend={{ position: 'left' }}
                        />

                        <table className="mt-5" style={styles.table}>
                            <tbody>
                                <tr style={styles.tableHeaderRow}>
                                    {['name', 'votes', 'percentage'].map((label, index) => (
                                        <th className="text-uppercase text-muted font-weight-bold" style={styles.tableHeader} key={index}>{t(`page.panel.tab.statistics.votes.tableHeader.${label}`)}</th>
                                    ))}
                                </tr>
                                {sortedStatistics.map((s) => (
                                    <tr key={s[0]} style={styles.tableRow}>
                                        <th>{electNames[s[0]]}</th>
                                        <th>{s[1]}</th>
                                        <th>{`${totalVotes ? round((100 / totalVotes) * s[1], 2) : 0}%`}</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </React.Fragment>
                ) : (
                    <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
                        <span className="text-muted">{t('page.panel.tab.statistics.votes.noVotes')}</span>
                    </div>
                )}
            </div>
        );
    }
}

VotingPieChart.propTypes = {
    elects: PropTypes.array.isRequired,
    statistics: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string
};

const styles = {
    table: {
        width: '100%'
    },
    tableHeader: {
        fontSize: 12
    },
    tableRow: {
        height: 30
    }
};

export default withTranslation()(VotingPieChart);