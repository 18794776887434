import React, { Component } from 'react';
import EvaIcon from '../EvaIcon';
import PropTypes from 'prop-types';

import './IconButton.scss';

class IconButton extends Component {
    handle_click = (e) => {
        if (!this.props.disabled && this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render() {
        const customStyles = this.props.style;
        const customClassName = this.props.className;

        const classNames = [
            'iconButton',
            (this.props.disabled ? 'disabled' : ''),
            'd-flex align-items-center justify-content-center',
            (customClassName ? customClassName : '')
        ];

        return (
            <div
                className={classNames.join(' ')}
                style={{ ...styles.container, ...(customStyles ? customStyles : {}) }}
                onClick={this.handle_click}
            >
                <EvaIcon name={this.props.name} style={{ fontSize: 18 }} outline={this.props.outline}/>
            </div>
        );
    }
}

IconButton.propTypes = {
    name: PropTypes.string.isRequired,
    outline: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string
};

const styleConstants = {
    size: 40
};

const styles = {
    container: {
        width:  styleConstants.size,
        height:  styleConstants.size,
        borderRadius: '50%'
    }
};

export default IconButton;