import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import EvaIcon from './EvaIcon';

class ConfirmationModal extends Component {
    handle_cancel = () => {if (this.props.onCancel) this.props.onCancel();};
    handle_submit = () => {if (this.props.onSubmit) this.props.onSubmit();};

    render() {
        const { t } = this.props;
        const { open, descriptionKey, data, submitButtonColor } = this.props;

        return (
            <Modal isOpen={open} toggle={this.handle_cancel}>
                <ModalHeader toggle={this.handle_cancel}>{t('ui.confirmationModal.headerLabel')}</ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center">
                    <EvaIcon name="question-mark" style={styles.icon}/>
                    <span className="flex-fill mx-4">{t(descriptionKey, data)}</span>
                </ModalBody>
                <ModalFooter>
                    <Button color="neutral" onClick={this.handle_cancel}>{t('ui.confirmationModal.cancelButtonLabel')}</Button>
                    <Button color={submitButtonColor ? submitButtonColor : 'primary'} onClick={this.handle_submit}>{t('ui.confirmationModal.submitButtonLabel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

ConfirmationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    descriptionKey: PropTypes.string.isRequired,
    data: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    submitButtonColor: PropTypes.string
};

const styles = {
    icon: {
        color: '#6076dd',
        fontSize: 70
    }
};

export default withTranslation()(ConfirmationModal);