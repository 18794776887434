import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './Confirm.scss';

import Api from '../../api/Api';
import EvaIcon from '../../components/EvaIcon';
import CenteredLoader from '../../components/CenteredLoader/CenteredLoader';

// Necessary due to react-router dropping support for class components
const ConfirmWrapper = (props) => {
    const { token } = useParams();
    return <Confirm token={token} {...props}/>;
};

class Confirm extends Component {
    state = {
        loading: true,
        loadingConfirm: false
    };

    async componentDidMount() {
        const { token } = this.props;

        try {
            const vote = await Api.getPendingVote(token);
            const elect = await Api.getElect(vote.vote);

            this.setState({ vote, elect });
        } catch (error) {
            this.setState({ error });
        } finally {
            this.setState({ loading: false });
        }
    }

    confirm = () => {
        const { token } = this.props;

        this.setState({ loadingConfirm: true });

        const updateSuccess = (success) => {
            setTimeout(() => this.setState({ success, loadingConfirm: false }), 500);
        };

        Api.confirmVote(token)
            .then(() => updateSuccess(true))
            .catch(() => updateSuccess(false));
    };

    change = async () => {
        const navigate = useNavigate();
        const { token } = this.props.match.params;

        try {
            await Api.deletePendingVote(token);
            navigate('/');
        } catch (error) {
            this.setState({ error: true });
        }
    };

    render() {
        const { t } = this.props;
        const { loading, vote, elect, error, loadingConfirm, success } = this.state;

        const timeout = { enter: 500, exit: 125 };

        return (
            <div style={styles.wrapper} className="confirm d-flex flex-column align-items-center justify-content-center">
                <TransitionGroup>
                    <CSSTransition
                        key={loading ? 0 : error ? 1 : 2}
                        classNames="fade"
                        timeout={timeout}
                    >
                        {loading ? (
                            <div/>
                        ) : error ? (
                            <div className="d-flex flex-column align-items-center">
                                <EvaIcon name="alert-triangle" outline style={styles.alertIcon}/>
                                <h3 className="m-0 mt-3">{t('page.confirm.error.' + error.name)}</h3>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h2 className="mb-1">{t('page.confirm.label')}</h2>
                                    <h5 className="mb-4">{vote.name}</h5>
                                    <div className="d-flex align-items-center">
                                        <ElectCard elect={elect}/>
                                    </div>
                                    <div style={{ height: 150 }} className="d-flex align-items-center justify-content-center">
                                        <TransitionGroup>
                                            <CSSTransition
                                                key={loadingConfirm === false && success === undefined ? 0 : success === undefined ? 1 : 2}
                                                classNames="fade"
                                                timeout={timeout}
                                            >
                                                {loadingConfirm === false && success === undefined ? (
                                                    <div className="d-flex flex-column align-items-center mt-5">
                                                        <Button className="m-0 custom-btn primary" onClick={this.confirm}>{t('page.confirm.button.confirm')}</Button>
                                                        <Button className="mt-3 btn-sm" onClick={this.change}>{t('page.confirm.button.change')}</Button>
                                                    </div>
                                                ) : success === undefined ? (
                                                    <div style={{ height: 100 }}>
                                                        <CenteredLoader/>
                                                    </div>
                                                ) : (
                                                    <EvaIcon
                                                        name={success ? 'checkmark' : 'close'}
                                                        outline
                                                        style={{ ...styles.resultIcon, ...styles.resultIcon[success ? 'success' : 'error'] }}
                                                    />
                                                )}
                                            </CSSTransition>
                                        </TransitionGroup>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

const styles = {
    wrapper: {
        width: '100vw',
        height: '100vh'
    },
    alertIcon: {
        fontSize: 60,
        color: '#828282'
    },
    resultIcon: {
        fontSize: 70,
        height: 77,
        success: {
            color: '#2ecc71'
        },
        error: {
            color: '#e74c3c'
        }
    }
};
Confirm.propTypes = {
    token: PropTypes.string.isRequired
};

export default withTranslation()(ConfirmWrapper);

class ElectCard extends Component {
    render() {
        const { elect } = this.props;

        return (
            <div className="d-flex flex-column align-items-center mx-3">
                <div style={electCardStyles.imageWrapper} className="imageWrapper">
                    <img src={elect.imageUrl} style={electCardStyles.image} alt=""/>
                </div>
                <h4 className="mt-3 text-center">{elect.name}</h4>
            </div>
        );
    }
}

const electCardStyles = {
    imageWrapper: {
        width: 125,
        height: 125,
        overflow: 'hidden',
        borderRadius: '50%',
        boxShadow: '0 0 30px 5px rgba(0, 0, 0, 0.15)'
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    }
};

ElectCard.propTypes = {
    elect: PropTypes.object.isRequired
};
