import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { Button } from 'reactstrap';

import './Settings.scss';

import Api from '../../../../api/Api';
import CenteredLoader from '../../../../components/CenteredLoader/CenteredLoader';
import ErrorModal from '../../../../components/ErrorModal';
import ConfirmationModal from '../../../../components/ConfirmationModal';

class Settings extends Component {
    state = {
        edited: false,
        success: false,
        loading: false,
        resetModalOpen: false
    };

    async componentDidMount() {
        try {
            let result = await Api.getSettings();

            result.votingEnabled = result.votingEnabled === '1';
            result.votingTimeframeEnabled = result.votingTimeframeEnabled === '1';

            this.setState({ settings: result });
            this.settings = result;
        } catch (error) {
            this.setState({ error: true });
        }
    }

    update = async () => {
        try {
            const { settings } = this.state;
            this.setState({ loading: true });
            await Api.updateSettings(settings);
            this.setState({ success: true, edited: false, loading: false });

            setTimeout(() => {
                this.setState({ success: false });
            }, 3000);
        } catch (error) {
            this.setState({ error: true });
        }
    };

    cancel = () => {
        this.setState({
            settings: this.settings,
            edited: false,
            success: false
        });
    };

    reset = async () => {
        await Api.reset();
        window.location.reload();
    };

    handle_settingsChange = (key, value='value') => (e) => {
        const settings = {
            ...this.state.settings,
            [key]: e.target[value]
        };
        this.setState({
            settings,
            edited: true
        });
    };

    handle_votingTimeframeChange = (changes) => {
        this.setState({
            settings: {
                ...this.state.settings,
                votingTimeframeFrom: changes.from,
                votingTimeframeTo: changes.to
            },
            edited: true
        });
    };

    render() {
        const { t } = this.props;
        const { settings, edited, success, loading, resetModalOpen, error } = this.state;

        let votingEnabled;//, votingTimeframeEnabled, votingTimeframeFrom, votingTimeframeTo;
        if (settings) {
            votingEnabled = settings.votingEnabled;
            //votingTimeframeEnabled = settings.votingTimeframeEnabled;
            //votingTimeframeFrom = settings.votingTimeframeFrom;
            //votingTimeframeTo = settings.votingTimeframeTo;
        }

        return (
            <div>
                {settings !== undefined ? (
                    <div className="settings" style={styles.settings}>
                        <Transition in={edited} timeout={250}>
                            {(state) => (
                                <div className={'d-flex mb-2 px-2 actionButtons window window-' + state}>
                                    <Button className="my-2" onClick={this.cancel} disabled={loading}>{t('page.panel.tab.settings.cancelButtonLabel')}</Button>
                                    <Button className="my-2 ml-3" onClick={this.update} disabled={loading}>{t('page.panel.tab.settings.updateButtonLabel')}</Button>
                                </div>
                            )}
                        </Transition>
                        <div className="d-flex">
                            <label className="custom-toggle">
                                <input type="checkbox" onChange={this.handle_settingsChange('votingEnabled', 'checked')} checked={votingEnabled}/>
                                <span className="custom-toggle-slider rounded-circle"></span>
                            </label>
                            <span className="ml-2">{t('page.panel.tab.settings.votingOpen.label')}</span>
                        </div>
                        {/*<Transition in={votingEnabled} timeout={250}>
                            {(state) => (
                                <div className={'pl-3 d-flex window window-' + state}>
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            id="votingTimeframeEnabled"
                                            className="custom-control-input"
                                            type="checkbox"
                                            onChange={this.handle_settingsChange('votingTimeframeEnabled', 'checked')}
                                            checked={votingTimeframeEnabled}
                                        />
                                        <label className="custom-control-label" htmlFor="votingTimeframeEnabled">
                                            {t('page.panel.tab.settings.votingOpen.votingTimeframeEnabled.label')}
                                        </label>
                                        <br/>
                                        <Transition in={votingTimeframeEnabled} timeout={250}>
                                            {(state) => (
                                                <div className={'windowVotingEnabled windowVotingEnabled-' + state} style={{ marginLeft: -40 }}>
                                                    <VotingTimeframeSelector
                                                        onChange={this.handle_votingTimeframeChange}
                                                        from={votingTimeframeFrom}
                                                        to={votingTimeframeTo}
                                                    />
                                                </div>
                                            )}
                                        </Transition>
                                    </div>
                                </div>
                            )}
                        </Transition>*/}
                        <Transition in={success} timeout={250}>
                            {(state) => (
                                <div className={'pl-3 window window-' + state}>
                                    <div className="alert alert-success" role="alert">
                                        {t('success.saved')}
                                    </div>
                                </div>
                            )}
                        </Transition>

                        <h4 className="mt-5">{t('page.panel.tab.settings.exports.headline')}</h4>
                        <div className="pl-3">
                            <a href="/api/statistics/export/summary" download>
                                {t('page.panel.tab.settings.exports.summary')}
                            </a><br/>
                            <a href="/api/statistics/export/votes" download>
                                {t('page.panel.tab.settings.exports.allVotes')}
                            </a><br/>
                            <br/>
                            <a
                                href="https://support.office.com/de-de/article/importieren-oder-exportieren-von-textdateien-txt-oder-csv-5250ac4c-663c-47ce-937b-339e391393ba"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('page.panel.tab.settings.exports.csvGuide')}
                            </a>
                        </div>

                        <h4 className="mt-5">{t('page.panel.tab.settings.reset.headline')}</h4>
                        <div>
                            <Button color="warning" onClick={() => this.setState({ resetModalOpen: true })}>
                                {t('page.panel.tab.settings.reset.buttonLabel')}
                            </Button>
                            <ConfirmationModal
                                descriptionKey="page.panel.tab.settings.reset.confirmationModal"
                                open={resetModalOpen}
                                submitButtonColor="warning"
                                onCancel={() => this.setState({ resetModalOpen: false })}
                                onSubmit={() => this.reset()}
                            />
                        </div>
                    </div>
                ) : (
                    <div style={styles.loaderWrapper}>
                        <CenteredLoader/>
                    </div>
                )}
                
                <ErrorModal descriptionKey="error.unexpected" open={error} onClose={() => this.setState({ error: false })}/>
            </div>
        );
    }
}

const styles = {
    loaderWrapper: {
        width: '100%',
        height: 100
    },
    settings: {
        width: 'auto'
    }
};

export default withTranslation()(Settings);